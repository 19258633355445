<template>
  <el-card class="register-details">
    <el-form
      ref="ruleForm"
      :label-position="labelPosition"
      :model="ruleForm"
      :rules="rules"
      label-width="130px"
      @submit.prevent.native="e => e.preventDefault()">
      <el-form-item
        label="Nome completo"
        prop="name"
        required>
        <el-input
          :autofocus="true"
          v-model="ruleForm.name" />
      </el-form-item>
      <el-row>
        <el-col :md="12">
          <el-form-item
            label="Celular"
            prop="phone"
            required>
            <el-input
              v-mask="[ '## ####-####', '## #####-####' ]"
              id="phoneInput"
              v-model="ruleForm.phone"
              placeholder="11 99999-9999"/>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Sexo"
            prop="gender"
            required>
            <el-select
              v-model="ruleForm.gender"
              placeholder="Escolha">
              <el-option
                :value="1"
                label="Masculino"/>
              <el-option
                :value="2"
                label="Feminino"/>
              <el-option
                :value="3"
                label="Prefere não responder"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="12">
          <el-form-item
            label="Aniversário"
            prop="birthdate"
            required>
            <el-input
              v-mask="'##/##/####'"
              v-model="ruleForm.birthdate"
              placeholder="DD/MM/YYYY"/>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="RG"
            prop="rg"
            required>
            <el-input v-model="ruleForm.rg" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="12">
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model="ruleForm.email"
              type="email"/>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="CEP"
            prop="cep"
            required>
            <el-input
              v-mask="'#####-###'"
              v-model="ruleForm.cep"
              @input.native="handleCEPInput" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        label="Logradouro"
        prop="street"
        required>
        <el-input
          :disabled="autofill.street"
          v-model="ruleForm.street" />
      </el-form-item>
      <el-row>
        <el-col :md="12">
          <el-form-item
            label="Número"
            prop="number"
            required>
            <el-input v-model="ruleForm.number" />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Complemento"
            prop="complement">
            <el-input v-model="ruleForm.complement" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :md="17">
          <el-form-item
            label="Cidade"
            prop="city"
            required>
            <el-input
              :disabled="autofill.city"
              v-model="ruleForm.city" />
          </el-form-item>
        </el-col>
        <el-col :md="7">
          <el-form-item
            disabled
            label="Estado"
            prop="uf"
            required>
            <el-input
              :disabled="autofill.uf"
              v-model="ruleForm.uf" />
          </el-form-item>
        </el-col>
      </el-row>

      <div class="form-actions">
        <el-button
          :loading="isSubmitting"
          type="primary"
          native-type="submit"
          @click="validateForm('ruleForm')">
          Salvar
        </el-button>
        <el-button
          :disabled="isSubmitting"
          @click="resetForm('ruleForm')">
          Resetar
        </el-button>
      </div>
    </el-form>
  </el-card>
</template>
<script>
import cep from "cep-promise";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phone from "@/helpers/phone";

export default {
  data() {
    const validateDate = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Campo obrigatório"));
      }

      const date = this.$moment(value, "DD/MM/YYYY");

      if (!date.isValid()) {
        return cb(new Error("Data inválida"));
      }

      if (date.isAfter(this.$moment().subtract(12, "years"))) {
        return cb(new Error("Muito novo"));
      }

      return cb();
    };
    return {
      autofill: {
        uf: false,
        state: false,
        street: false
      },
      isSubmitting: false,
      labelPosition:
        document.documentElement.clientWidth < 720 ? "top" : "right",
      ruleForm: {
        name: "",
        gender: "",
        birthdate: "",
        rg: "",
        phone: "",
        email: "",
        cep: "",
        street: "",
        number: "",
        complement: "",
        city: "",
        uf: ""
      },
      rules: {
        name: [{ required: true, message: "Campo obrigatório" }],
        gender: [{ required: true, message: "Campo obrigatório" }],
        birthdate: [
          { required: true, message: "Campo obrigatório" },
          { validator: validateDate, trigger: "blur" }
        ],
        rg: [{ required: true, message: "Campo obrigatório" }],
        phone: [{ required: true, message: "Campo obrigatório" }],
        cep: [{ required: true, message: "Campo obrigatório" }],
        street: [{ required: true, message: "Campo obrigatório" }],
        number: [{ required: true, message: "Campo obrigatório" }],
        city: [{ required: true, message: "Campo obrigatório" }],
        uf: [{ required: true, message: "Campo obrigatório" }]
      }
    };
  },
  computed: mapGetters({
    client: types.CLIENT_DETAILS
  }),
  mounted() {
    this.ruleForm = {
      ...this.ruleForm,
      ...this.client,
      ...this.client.address
    };
    if (this.ruleForm.birthdate) {
      this.ruleForm.birthdate = this.$moment(
        this.ruleForm.birthdate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    }
    this.$nextTick(() => {
      const $phoneInput = this.$el.querySelector("#phoneInput");
      $phoneInput.dispatchEvent(new Event("blur", { bubbles: true }));
      this.$refs.ruleForm.clearValidate();
    });
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    async submitForm() {
      this.isSubmitting = true;
      const payload = this.generatePayload();
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, payload);
        this.$notify.success({
          title: "Sucesso",
          message: "Os dados foram salvos na plataforma"
        });
        this.isSubmitting = false;
        this.$router.push({ name: "Cadastro - Concessão de crédito" });
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isSubmitting = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.autofill.uf = false;
      this.autofill.street = false;
      this.autofill.city = false;
    },
    async handleCEPInput(e) {
      const inputedCep = e.target.value.replace("-", "");
      if (inputedCep.length === 8) {
        const response = await cep(inputedCep);
        delete response.cep;
        response.uf = response.state;
        this.autofill.uf = !!response.state;
        this.autofill.street = !!response.street;
        this.autofill.city = !!response.city;
        this.ruleForm = { ...this.ruleForm, ...response };
      }
    },
    generatePayload() {
      return {
        user: {
          name: this.ruleForm.name,
          gender: this.ruleForm.gender,
          birthdate: this.$moment(this.ruleForm.birthdate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          rg: this.ruleForm.rg,
          phone: phone.unformat(this.ruleForm.phone),
          email: this.ruleForm.email,
          address: {
            cep: this.ruleForm.cep.replace("-", ""),
            street: this.ruleForm.street,
            number: this.ruleForm.number,
            complement: this.ruleForm.complement,
            city: this.ruleForm.city,
            uf: this.ruleForm.uf
          },
          status_id: 1
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.register-details {
  max-width: 720px;
  margin: 1.5rem auto 0;

  @include large-down {
    margin-bottom: 4rem;
    .el-form-item__label {
      float: none;
    }
    .el-form-item__content {
      margin-left: 0;
    }
  }

  .form-actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    .el-button {
      flex: 0 100%;
      margin-bottom: 1rem;

      @include large-up {
        flex: 0 200px;
        margin-bottom: 0;
      }

      @include large-down {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
